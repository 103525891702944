import React, { useEffect, useRef } from 'react';
import { animateSecurity } from '../helpers/animateSecurity';

export const Security = () => {
    const ref = useRef();
    useEffect(() => {
        animateSecurity(ref);
    }, []);
    return (
        <section className="security" ref={ ref }>
            <div className="info">
                <h2 className="section-title">Tu información está 100% segura con MitHealth</h2>
                <p className="section-subtitle">MitHealth cumple con toda la normativa nacional e internacional referente al manejo de información clínica</p>
                <div className="extras">
                    <div className="extra">
                        <h3>Cumplimiento de la normativa</h3>
                        <p>MitHealth no solo cumple con todos las disposiciones de la NOM-024 sobre el manejo de información médica, sino que también cumple con las disposiciones de la ley HIPAA de los Estados Unidos</p>
                    </div>
                    <div className="extra">
                        <h3>Encriptación de datos en reposo</h3>
                        <p>MitHealth aprovecha la infraestructura de la nube de Google para almacenar y encriptar toda tu información usando del algoritmo de encriptación AES-256</p>
                    </div>
                    <div className="extra">
                        <h3>Conexión segura HTTPS</h3>
                        <p>Toda comunicación de datos dentro de la plataforma de MitHealth es encriptada a través de los protocolos TLS/SSL, usando una conexión HTTPS</p>
                    </div>
                    <div className="extra">
                        <h3>Autenticación y verificación de cuentas</h3>
                        <p>A todos los usuarios de MitHealth, ya sean médicos o pacientes, se les exige que verifiquen sus cuentas con una dirección de correo electrónico válida</p>
                    </div>
                </div>
            </div>
            <div className="illustration animated">
                <img src="assets/illustrations/secure-server.svg" alt="MitHealth es 100% seguro"/>
            </div>
        </section>
    )
}
