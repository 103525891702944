import React from 'react';
import { registerSignUp } from '../../helpers/registerSignUp';
import { useFetch } from '../../hooks/useFetch';
import { BasicPrice } from './BasicPrice';
import { PremiumPrice } from './PremiumPrice';

export const Pricing = () => {
    const url = 'https://mithealth-server-dxmvqexzdq-uc.a.run.app/billing/prices';
    // const url = 'http://localhost:8080/billing/prices';
    const { data: prices } = useFetch(url);
    return (
        <section className="pricing">
            <h2 className="section-title">Obtén tu cuenta de MitHealth Medics ahora</h2>
            <div className="price-cards">
                <BasicPrice prices={ prices } />
                <PremiumPrice prices={ prices } />
            </div>
            <div className="ctas">
                <a href="https://medics.mithealth.app/public/register" onClick={ registerSignUp } className="btn btn-primary-reversed">
                    Ingresa ahora <i className="fas fa-chevron-right btn-icon"></i>
                </a>
                <a href="https://demo.mithealth.app" className="btn btn-secondary-reversed">
                    Explora nuestro demo <i className="fas fa-chevron-right btn-icon"></i>
                </a>
            </div>
            <div className="decoration"></div>
        </section>
    )
}
