import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export const Navbar = () => {
    const routes = [
        { label: 'Conoce MitHealth', route: '/' },
        { label: 'Para médicos', route: '/medics' },
        { label: 'Para pacientes', route: '/patients' },
    ];
    const createRouteItems = ({ label, route }, idx) => (
        <li key={ idx }>
            <NavLink className="nav-link" activeClassName="active" exact to={ route }>
                { label }
            </NavLink>
        </li>
    )
    return (
        <header>
            <nav className="header-nav">
                <Link to="/" className="brand">
                    <img src="assets/logo.svg" alt="MitHealth Logo" className="logo-img"/>
                    <span>MitHealth</span>
                </Link>
                <ul className="nav-list">
                    { routes.map(createRouteItems) }
                </ul>
            </nav>
        </header>
    )
}
