import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const Metatags = ({ title, description }) => {
    return (
        <Helmet>
            {/* Regular meta tags */}
            <title>{ title }</title>
            <meta name="description" content={ description }/>
            {/* Schema.org tags */}
            <meta itemprop="name" content={ title }/>
            <meta itemprop="description" content={ description }/>
            {/* OpenGraph Tags */}
            <meta property="og:title" content={ title } />
            <meta property="og:description" content={ description } />
            <meta property="og:site_name" content={ title } />
            {/* Twitter Tags */}
            <meta name="twitter:title" content={ title }/>
            <meta name="twitter:description" content={ description }/>
        </Helmet>
    )
}

Metatags.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};
