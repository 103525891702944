import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// Shared Components
import { Footer } from './components/navigation/Footer';
import { Navbar } from './components/navigation/Navbar';
// Pages
import { HomePage } from './pages/HomePage';
import { MedicsPage } from './pages/MedicsPage';
import { PatientsPage } from './pages/PatientsPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { TermsPage } from './pages/TermsPage';

const App = () => {
    return (
        <>
            <Navbar />
            <main>
                <Switch>
                    <Route path="/medics">
                        <MedicsPage />
                    </Route>
                    <Route path="/patients">
                        <PatientsPage />
                    </Route>
                    <Route path="/terms">
                        <TermsPage />
                    </Route>
                    <Route path="/privacy">
                        <PrivacyPage />
                    </Route>
                    <Route path="/">
                        <HomePage />
                    </Route>
                    <Redirect to="/"/>
                </Switch>
            </main>
            <Footer />
        </>
    )
}

export default App;