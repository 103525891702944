import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaqList } from '../components/FaqList';
import { MainFeatures } from '../components/features/MainFeatures';
import { ShowcaseFeatures } from '../components/features/ShowcaseFeatures';
import { Metatags } from '../components/Metatags';
import { Pricing } from '../components/pricing/Pricing';
import { Security } from '../components/Security';
import { animateHero } from '../helpers/animateHero';
import { registerSignUp } from '../helpers/registerSignUp';

export const MedicsPage = () => {
    useEffect(() => {
        animateHero();
    }, []);
    const features = [
        {
            title: 'Sin instalaciones',
            subtitle: <>Basado en web, MitHealth Medics está disponible en todos tus dispositivos</>,
            graphic: 'globe'
        }, {
            title: 'Administra tu perfil',
            subtitle: <>Mantén al día tu dirección de consultorio, días y horas hábiles, así como tu información profesional</>,
            graphic: 'user-md'
        }, {
            title: 'Tu salud también es importante',
            subtitle: <>Puedes hacer uso de <Link to="/patients" className="text-link">MitHealth Patients</Link> con tu cuenta de MitHealth Medics</>,
            graphic: 'star-of-life'
        }
    ];
    return (
        <>
            <Metatags
                title="MitHealth Medics - Software eficiente y asequible para una mejor atención, sin costo y exclusivo para México"
                description="MitHealth es la solución completa para mejorar la atención de tu consultorio particular, disponible en todos tus dispositivos. Pruebalo gratis ahora" />
            {/* Hero section */}
            <section className="secondary-hero">
                <div className="section-image hero-img">
                    <img src="assets/images/home.png" alt="MitHealth Medics"/>
                </div>
                <div className="text">
                    <h1 className="hero-title hero-anim">Software eficiente y asequible para una mejor atención</h1>
                    <h2 className="hero-subtitle hero-anim">MitHealth es la solución completa para mejorar la atención de tu consultorio particular, disponible en todos tus dispositivos</h2>
                    <div className="cta primary-cta hero-anim">
                        <span>MitHealth Medics</span>
                        <a href="https://medics.mithealth.app/public/register" onClick={ registerSignUp } className="btn-primary btn">
                            Ingresa ahora <i className="fas fa-chevron-right btn-icon"></i>
                        </a>
                    </div>
                    <div className="cta hero-anim">
                        <span>MitHealth Medics Demo</span>
                        <a href="https://demo.mithealth.app" className="btn-secondary btn">
                            Explora nuestro demo <i className="fas fa-chevron-right btn-icon"></i>
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <ShowcaseFeatures features={ features } />
            </section>
            {/* App features */}
            <section className="app-features">
                <h2 className="section-title">MitHealth Medics tiene todo lo que necesitas para tus servicios de consulta externa</h2>
                <MainFeatures />
            </section>
            <Security />
            <Pricing />
            <FaqList />
        </>
    )
}
