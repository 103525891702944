import { gsap } from 'gsap';

export const animateFeatures = (featureElem) => {
    const intObs = new IntersectionObserver((entries, observer) => {
        entries.forEach(({ isIntersecting }) => {
            if (isIntersecting) {
                gsap.to('.feature', { duration: 1, opacity: 1, y: 0, stagger: 0.3 });
                observer.disconnect();
            }
        });
    }, { threshold: 0.4 });
    intObs.observe(featureElem.current);
}
