import React from 'react';

export const FaqList = () => {
    const faqs = [
        { 
            question: '¿Si tengo una cuenta de MitHealth Medics puedo usar MitHealth Patients?',
            answer: <>Por supuesto, con tu cuenta de MitHealth Medics podrás utilizar MitHealth Patients tan pronto como esté disponible</>
        }, { 
            question: '¿Recibo actualizaciones gratuitas?',
            answer: <>Tanto MitHealth Medics como MitHealth Patients recibirán actualizaciones para añadir funciones y corregir errores sin ningún costo adicional</>
        }, { 
            question: '¿Si MitHealth cumple con la NOM-024, por qué no tiene certificación?',
            answer: <>Lamentablemente todos los procesos de certificación por parte de la DGIS (Dirección General de Información en Salud) están suspendidos hasta que termine la emergencia sanitaria. En cuanto sea posible se iniciara el tramite de certificación de MitHealth</>
        }, {
            question: '¿MitHealth cuenta con alguna herramienta para ayudar en la atención del COVID-19?',
            answer: <>Las herramientas de MitHealth son un soporte a la atención médica en general, no tiene una herramienta en específico para el COVID-19. Si tienes una idea para una herramienta así nos encantaría escucharla, puedes escribirnos a nuestro&nbsp;
                <a href="mailTo:mithealth.service@mithealth.app" className="text-link">correo de soporte</a>
            </>
        },
    ];
    const createQuestion = ({ question, answer }, key) => (
        <div className="question" key={ key }>
            <i className="fas fa-question-circle"></i>
            <div>
                <h3>{ question }</h3>
                <p>{ answer }</p>
            </div>
        </div>
    )
    return (
        <section className="faqs">
            <div className="faq">
                { faqs.map(createQuestion) }
            </div>
        </section>
    )
}
