import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <footer>
            <div className="copy">
                <h3>&copy; MitHealth, 2020</h3>
                <div className="social-btns">
                    <a href="mailTo:mithealth.service@mithealth.app">
                        <i className="fas fa-envelope"></i>
                    </a>
                    <a href="https://www.facebook.com/MitHealthApp" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook"></i>
                    </a>
                </div>
            </div>
            <div className="footer-nav">
                <h4>Aplicación</h4>
                <ul>
                    <li><Link to="/medics">Para médicos</Link></li>
                    <li><Link to="/patients">Para pacientes</Link></li>
                </ul>
            </div>
            <div className="footer-nav">
                <h4>Legal</h4>
                <ul>
                    <li><Link to="/privacy">Aviso de privacidad</Link></li>
                    <li><Link to="/terms">Términos y condiciones</Link></li>
                </ul>
            </div>
        </footer>
    )
}
