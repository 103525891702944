import { gsap } from 'gsap';

export const animateSecurity = (securityElem) => {
    const intObs = new IntersectionObserver((entries, observer) => {
        entries.forEach(({ target, isIntersecting }) => {
            const illustration = target.children[1];
            if (isIntersecting) {
                illustration.classList.add('fadeInUp');
                gsap.to('.extra', { duration: 0.6, opacity: 1, y: 0, stagger: 0.3 });
                observer.disconnect();
            }
        });
    }, { threshold: 0.4 });
    intObs.observe(securityElem.current);
}
