import React from 'react';
import { Link } from 'react-router-dom';

export const MainFeatures = () => {
    const features = [
        {
            title: 'Control de citas',
            image: 'shared-home',
            subfeatures: [
                <>Revisa tus citas en cualquier parte. No necesitas preocuparte por agendar las citas, los pacientes pueden hacerlo por ti</>,
                <>Personaliza tu calendario ajustando tus días y horas hábiles, o agrega excepciones para que nadie pueda agendar una cita en tus vacaciones</>,
                <>Tus pacientes pueden agendar, cancelar o solicitarte una modificación a sus citas desde <Link to="/patients" className="text-link">MitHealth Patients</Link></>,
            ]
        }, {
            title: 'Biblioteca de medicamentos personal',
            image: 'library',
            subfeatures: [
                <>Crea una biblioteca personal con los medicamentos que más frecuentemente recetes para facilitar aún más el proceso de prescripción</>,
                <>También puedes buscar y copiar medicamentos de la biblioteca de los otros médicos de MitHealth a tu propia biblioteca</>,
            ]
        }, {
            title: 'Administra a todos tus pacientes',
            image: 'shared-patient',
            subfeatures: [
                <>Si tus pacientes usan <Link to="/patients" className="text-link">MitHealth Patients</Link>, solo buscalos y agregalos, agilizando el proceso de registro.</>,
                <>Toda la información que necesitas del paciente está en su perfil, incluyendo la atención que han agregado otros médicos, mejorando la calidad de la atención al paciente.</>,
                <>Tus pacientes también pueden agregarte desde <Link to="/patients" className="text-link">MitHealth Patients</Link></>,
            ]
        }, {
            title: 'Control de tratamientos e historía clínica',
            image: 'shared-meds',
            subfeatures: [
                <>Obtén acceso a la historia clínica completa del paciente, incluyendo la atención que han agregado otros médicos y mediciones que el propio paciente actualiza, mejorando la calidad de la atención al paciente, como si realizaras una interconsulta.</>,
                <>Obtén acceso a todas las recetas del paciente, incluyendo las que han recetado otros médicos, evitando contraindicaciones, mejorando la calidad de la atención al paciente.</>,
                <>Tus pacientes pueden actualizar el progreso diario de cada uno de sus tratamientos desde <Link to="/patients" className="text-link">MitHealth Patients</Link></>,
            ]
        },
    ];
    const createFeature = ({ title, image, subfeatures }, key) => (
        <div className="app-feature" key={ key }>
            <img src={`assets/images/${ image }.png`} alt={ title } className="feature-img"/>
            <div className="feature-text">
                <h3>{ title }</h3>
                <ul>
                    { subfeatures.map((subfeature, idx) => <li key={ idx }><i className="far fa-check-circle"></i> { subfeature }</li>) }
                </ul>
            </div>
        </div>
    )
    return (
        <div className="app-features-grid">
            { features.map(createFeature) }
        </div>
    )
}
