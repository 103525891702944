import React, { useEffect, useRef } from 'react';
import { animateFeatures } from '../../helpers/animateFeatures';

export const ShowcaseFeatures = ({ features, imgFeature = false, mobileImg = false }) => {
    const featuresRef = useRef();
    useEffect(() => {
        animateFeatures(featuresRef);
    }, []);
    const createFeatures = ({ title, subtitle, graphic }, key) => (
        <div className="feature" key={ key }>
            {
                imgFeature
                    ? <img src={`assets/images/${ graphic }.png`} alt={ title }
                        className={ mobileImg ? 'feature-img mobile-img' : 'feature-img' }/>
                    : <span className="icon"><i className={`fas fa-${ graphic }`}></i></span>
            }
            <h3>{ title }</h3>
            <p>{ subtitle }</p>
        </div>
    )
    return (
        <div className="features-grid" ref={ featuresRef }>
            { features.map(createFeatures) }
        </div>
    )
}
