import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { FaqList } from '../components/FaqList';
import { ShowcaseFeatures } from '../components/features/ShowcaseFeatures';
import { Metatags } from '../components/Metatags';
import { Roadmap } from '../components/Roadmap';
import { Security } from '../components/Security';

export const HomePage = () => {
    useEffect(() => {
        gsap.to('.hero-anim', { duration: 1, opacity: 1, y: 0, stagger: 0.3 });
    }, []);
    const features = [
        {
            title: 'Control de citas',
            subtitle: <>Nunca más pierdas tus citas con tus médicos / pacientes</>,
            graphic: 'shared-home'
        }, {
            title: 'Lista de contactos',
            subtitle: <>Agrega, contacta y ve el perfil de tus médicos / pacientes</>,
            graphic: 'shared-patients'
        }, {
            title: 'Control de tratamientos',
            subtitle: <>Receta a tus pacientes o dejale ver a tus médicos el progreso de tu tratamiento</>,
            graphic: 'shared-meds'
        }
    ];
    return (
        <>
            <Metatags 
                title="MitHealth - La plataforma de expediente clínico electrónico para médicos y pacientes, sin costo y exclusivo para México"
                description="MitHealth es la plataforma digital que reúne a médicos y pacientes para mejorar la calidad de la atención médica, sin costo y exclusivo para México" />
            {/* Hero section */}
            <section className="hero">
                <div className="section-image hero-img hero-anim">
                    <img src="assets/illustrations/hero.svg" alt="MitHealth"/>
                </div>
                <h1 className="hero-title hero-anim">La plataforma de expediente clínico electrónico para médicos y pacientes</h1>
                <h2 className="hero-subtitle hero-anim">MitHealth es la plataforma digital que reúne a médicos y pacientes para mejorar la calidad de la atención médica</h2>
            </section>
            {/* Features section */}
            <section className="features">
                <h2 className="section-title">Funciones pensadas para médicos particulares... y también para pacientes</h2>
                <ShowcaseFeatures features={ features } imgFeature={ true } />
                <p>Conoce más acerca de las funciones de MitHealth</p>
                <div className="ctas">
                    <div className="cta">
                        <Link to="/medics" className="btn btn-primary">
                            Para médicos <i className="fas fa-chevron-right btn-icon"></i>
                        </Link>
                    </div>
                    <div className="cta">
                        <Link to="/patients" className="btn btn-primary">
                            Para pacientes <i className="fas fa-chevron-right btn-icon"></i>
                        </Link>
                    </div>
                </div>
            </section>
            <Roadmap />
            <Security />
            <FaqList />
        </>
    )
}
