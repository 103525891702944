import React, { useEffect } from 'react';
import { FaqList } from '../components/FaqList';
import { ShowcaseFeatures } from '../components/features/ShowcaseFeatures';
import { Metatags } from '../components/Metatags';
import { Security } from '../components/Security';
import { animateHero } from '../helpers/animateHero';

export const PatientsPage = () => {
    useEffect(() => {
        animateHero();
    }, []);
    const features = [
        {
            title: 'Control de citas',
            subtitle: <>¡Nunca más pierdas tus citas médicas! Puedes revisar todas tus citas y agendar nuevas citas desde la app, ¡solo espera a que tu médico confirme y listo!</>,
            graphic: 'appointments2'
        }, {
            title: 'Tus contactos médicos',
            subtitle: <>Mantén a todos tus contactos médicos en un solo sitio y contactalos de manera sencilla. Busca y agrega a tus médicos en MitHealth o invitalos a que prueben MitHealth</>,
            graphic: 'medics2'
        }, {
            title: 'Control de tratamientos',
            subtitle: <>Gracias a MitHealth ya no te preguntarás si tomaste el medicamento correcto o no, todas tus recetas y medicamentos estarán disponibles en la app</>,
            graphic: 'treatments3'
        }
    ];
    return (
        <>
            <Metatags 
                title="MitHealth: donde los pacientes tomamos el control de nuestra atención médica"
                description="Se activo en tu proceso de atención médica. Mejora la calidad de esta y acércate a tus médicos" />
            {/* Hero section */}
            <section className="secondary-hero">
                <div className="section-image hero-img">
                    <img src="assets/images/treatments1.png" alt="MitHealth Patients"/>
                </div>
                <div className="text">
                    <h1 className="hero-title hero-anim">MitHealth: donde los pacientes tomamos el control de nuestra atención médica</h1>
                    <h2 className="hero-subtitle hero-anim">Se activo en tu proceso de atención médica. Mejora la calidad de esta y acércate a tus médicos</h2>
                    <div className="cta hero-anim">
                        <span>MitHealth Patients</span>
                        <a href="https://play.google.com/store/apps/details?id=app.mithealth.patients" className="btn btn-primary">
                            <i className="fab fa-google-play"></i>&nbsp;Descarga en Play Store
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <h2 className="section-title">MitHealth para pacientes no tiene ningún costo adicional</h2>
                <ShowcaseFeatures features={ features } imgFeature={ true } mobileImg={ true } />
            </section>
            <Security />
            <FaqList />
        </>
    )
}
