import React, { useEffect, useState } from 'react';

export const BasicPrice = ({ prices }) => {
    const planBenefits = [
        <>Todas las funciones disponibles de MitHealth Medics</>,
        <>Hasta 50 pacientes registrados</>,
        <>Hasta 150 citas al mes</>,
        <>Hasta 20 medicamentos en tu biblioteca</>,
    ];
    const [ amount, setAmount ] = useState();
    useEffect(() => {
        const price = prices && prices.find(price => price.unit_amount === 0);
        price && setAmount(Number(price.unit_amount / 100).toFixed(2));
    }, [prices]);
    return (
        <div className="card">
            <div className="pill">Básico</div>
            <h3 className="price">
                <sup>$</sup>{ amount }
            </h3>
            <ul className="plan-benefits">
                {
                    planBenefits.map((benefit, key) => (
                        <li key={ key }><i className="far fa-check-circle"></i> { benefit }</li>
                    ))
                }
            </ul>
        </div>
    )
}
