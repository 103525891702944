import React, { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';

export const Roadmap = () => {
    const feats = [
        { label: 'Fase beta de MitHealth Medics', completed: true, length: 14 },
        { label: 'Lanzamiento de MitHealth Medics', completed: true, length: 24 },
        { label: 'Lanzamiento de MitHealth Patients', completed: true, length: 25 },
        { label: 'Mensajería para pacientes y médicos', completed: false, length: 25 },
    ];
    const initialWidth = feats.reduce((sum, { length, completed }) => completed ? sum + length : sum, 0);
    const nextTotalWidth = feats.slice(0, 3).reduce((sum, { length }) => sum + length, 0);
    const [width, setWidth] = useState(initialWidth);
    useEffect(() => {
        const nextExpectedCompletion = new Date('Dec 20, 2021');
        const difference = 1 + (nextTotalWidth - differenceInDays(nextExpectedCompletion, new Date())) / 100;
        setWidth(w => (w * difference > 100) ? 100 : w * difference);
    }, [ nextTotalWidth ]);
    const createFeatMarker = ({ label, completed }, key) => (   
        <div className="marker" key={ key }>
            <span className={ `marker-dot ${ completed ? 'completed' : '' }` }></span>
            <span className="marker-text">{ label }</span>
        </div>
    )
    return (
        <section className="roadmap">
            <h2 className="section-title">Roadmap 2020 - 2021</h2>
            <div className="progress-outer">
                <div className="progress-inner" style={{ width: `${ width }%` }}></div>
                { feats.map(createFeatMarker) }
            </div>
        </section>
    )
}
